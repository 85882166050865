import React from 'react';
import './avisos.css';
import UserService from '../../services/userService';
import Navbar from '../../components/Navbar';

export default function Avisos() {



    const updateWarningStatus = async e => {
        e.preventDefault();
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            user.warning = true;
            localStorage.setItem("user", JSON.stringify(user));
            await UserService.warningNotification()
            setTimeout(() => {
                window.location.reload()
            }, 4000)
        } catch (err) {
            console.log(err)
        }
    }


    const user = JSON.parse(localStorage.getItem('user'));
    const permission = user.warning;

    return (
        <div>
            <Navbar warning={true} />
            <div className='container-fluid'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-lg-3 d-flex justify-content-center z-index position-relative slideDown1'>
                        <figure className="figure d-flex justify-content-center">
                            <div className='d-flex justify-content-center position-absolute'>
                                <div className='d-lg-block d-xl-block d-none'>
                                    <img src={`${process.env.REACT_APP_URL}/dist/icons/clipbalao.png`} width="50" height="190" />
                                </div>
                            </div>
                            <div className='text-center balon d-flex align-items-center pt-balon'>
                                <div className='p-2'>
                                    <h5 className='color-h5'>Propriedade Intelectual</h5>
                                    <div className='d-flex align-items-center'>
                                        <h6 className='color-h6'>Dati tem seu INPI no Processo nº: BR 51 2018 001120-9</h6>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </div>
                    <div className='col-lg-3 d-flex justify-content-center z-index position-relative slideDown2'>
                        <figure className="figure d-flex justify-content-center">
                            <div className='d-flex justify-content-center position-absolute'>
                                <div className='d-lg-block d-xl-block d-none'>
                                    <img src={`${process.env.REACT_APP_URL}/dist/icons/clipbalao.png`} width="50" height="190" />
                                </div>
                            </div>
                            <div className='text-center balon d-flex align-items-center pt-balon'>
                                <div className='p-2'>
                                    <h5 className='color-h5'>Compartilhamento</h5>
                                    <div className='d-flex align-items-center'>
                                        <h6 className='color-h6'>O compartilhamento desse material para não-usuários do sistema é totalmente proibido sob pena de pagamento de multa e indenização por perdas e danos.</h6>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </div>
                    <div className='col-lg-3 d-flex justify-content-center z-index position-relative slideDown3'>
                        <figure className="figure d-flex justify-content-center">
                            <div className='d-flex justify-content-center position-absolute'>
                                <div className='d-lg-block d-xl-block d-none'>
                                    <img src={`${process.env.REACT_APP_URL}/dist/icons/clipbalao.png`} width="50" height="190" />
                                </div>
                            </div>
                            <div className='text-center balon d-flex align-items-center pt-balon'>
                                <div className='p-2'>
                                    <h5 className='color-h5'>Pessoas fora Empresa</h5>
                                    <div className='d-flex align-items-center'>
                                        <h6 className='color-h6'>Caso precise compartilhar o conteúdo desses vídeos com pessoas de fora da sua empresa, nos avise para que possamos analisar seu pedido.</h6>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </div>
                    <div className='col-lg-3 d-flex justify-content-center z-index position-relative slideDown4'>
                        <figure className="figure d-flex justify-content-center">
                            <div className='d-flex justify-content-center position-absolute'>
                                <div className='d-lg-block d-xl-block d-none'>
                                    <img src={`${process.env.REACT_APP_URL}/dist/icons/clipbalao.png`} width="50" height="190" />
                                </div>
                            </div>
                            <div className='text-center balon d-flex align-items-center pt-balon'>
                                <div className='p-2'>
                                    <h5 className='color-h5'>Dedicado a Você</h5>
                                    <div className='d-flex align-items-center'>
                                        <h6 className='color-h6'>Fizemos esse espaço para sua empresa. Compartilhamentos são permitidos apenas para pessoas da sua empresa. </h6>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </div>
                </div>
                <div className='row d-flex mr-auto pb-3 justify-content-center'>
                    <div className='d-lg-flex'>
                        {permission ? <></> :
                            <button type="button" onClick={updateWarningStatus} className="btn btn-outline-primary">
                                CONCORDAR E PROSSEGUIR
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}