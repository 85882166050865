import videoService from "../../services/videoService";
import './VideoCard.css';
export default function VideoCard(props) {
    const item = props.video;
    return (
        <div id={item._id} className="video-card mb-3 border-0">
            <div className="d-flex justify-content-center">
                <img className="img-fluid" src={`https://img.youtube.com/vi/${videoService.getVideoIdByLink(item.link)}/mqdefault.jpg`}></img>
            </div>
            
            <div className="video-card-body">
                <h4 className='text-center' >{item.title}</h4>
                <h6 className='text-center'>{item.subtitle}</h6>
                <p className='text-center'> <img src={`${process.env.REACT_APP_URL}/dist/icons/cronometro.png`} height='25px'></img> {item.duration} </p>
            </div>
        </div>

    )
}