import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import '../videos.css'
import Navbar from '../../../components/Navbar';
import LikeDislike from '../../../components/LikeDislike';
import Like from '../../../components/LikeDislike/Like';
import Dislike from '../../../components/LikeDislike/Dislike';
import { getJson } from '../../../services/requests';
import VideoCardDetail from '../../../components/VideoCardDetail';
import { CircularProgress } from '@mui/material';

export default function VideosDetail() {
    const [getIdVideos, setgetIdVideos] = useState([]);
    const [like, setLike] = useState(false);
    const [dislike, setDislike] = useState(false);
    const [loading, setLoading] = useState(false)


    const handleLike = () => {
        setLike(true)
    }
    const handleDislike = () => {
        setDislike(true)
    }
    const { video_id } = useParams()

    useEffect(async () => {
        setLoading(true)
        const response = await getJson(`api/v1/video/by-video/${video_id}`)
        setgetIdVideos(response.data)
        setLoading(false)
    }, [])

    return (
        <div>
            <Navbar />
            <div className='d-flex justify-content-center' >{loading ? <CircularProgress className='loading-video-card' /> :
                <div className='container-fluid'>
                    <div className='row d-flex justify-content-center p-2'>
                        <div className='col-lg-3'>
                            <a href="/">
                                <button type="button" className="btn btn-outline-primary justify-content-center mr-3"><i className="fas fa-undo-alt" /></button>
                            </a>
                        </div>

                        <div className="text-white mt-3 col-lg-5">
                            {getIdVideos &&
                                <VideoCardDetail video={getIdVideos} />
                            }
                        </div>
                        <div className='col-lg-3' />
                    </div>
                    <div className="col-lg-3 p-auto m-auto">
                        <p className='text-center'>Esse video te ajudou?</p>
                        <div className="row d-flex  justify-content-center " >
                            <Like onClick={handleLike} value={video_id} type="video" />
                            <Dislike onClick={handleDislike} value={video_id} type="video" />
                        </div>

                    </div>
                </div>
            }
            </div>
        </div>
    )
}
