import { toast } from "react-toastify"
import HttpResponseCodes from "../helpers/HttpResponseCodes"
import { deletar, get, getJson, post, put } from "./requests"

const CompanyService = {
  getAll: async function () {
    return await getJson("api/v1/company")
  },

  create: async function (company) {
    try {
      const response = await post('api/v1/company', company)
        .then(toast.info('Empresa sendo criada!', {

          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }))
        .then(response => {
          toast.success('Empresa criada com sucesso!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        })
    } catch (err) {
      toast.error('Algo deu errado!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  },

  edit: async function (company) {
    try {
      const response = await put('api/v1/company', company)
        .then(toast.info('Empresa sendo editada!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }))
        .then(response => {
          toast.success('Empresa editada com sucesso!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        })
    } catch (err) {
      toast.error('Algo deu errado!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  },

  delete: async function (id) {
    try {
      const response = await deletar(`api/v1/company/${id}`)
        .then(toast.info('Empresa sendo deletada!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }))
        .then(response => {
          toast.success('Empresa deletada com sucesso!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        })
    } catch (err) {
      toast.error('Algo deu errado!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  },
}

export default CompanyService