export const HttpResponseCodes = {
    OK: 200,
    Created:201,
    Accepted:202,
    BadRequest:400,
    Unauthorized:401,
    PaymentRequired:402,
    Forbidden:403,
    NotFound:404,
    MethodNotAllowed:405,
    UnprocessableEntity:422,
    InternalServerError:500
} 


export default HttpResponseCodes