import React, { useState, useEffect } from 'react';
import CompanyService from '../../services/companyService';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Navbar from '../../components/Navbar';
import { Alert, Button, CircularProgress } from '@mui/material';
import UserService from '../../services/userService';
import InputMask from 'react-input-mask';
import './company.css';
import { get } from '../../services/requests';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';

export default function CompanyRegister() {
  const [allCompanies, setAllCompanies] = useState([]);
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [companyEdit, setCompanyEdit] = useState({})
  const [users, setUsers] = useState([])
  const [buttonStatus, setButtonStatus] = useState();

  const GetAllCompanies = async e => {
    setLoading(true)
    const response = await CompanyService.getAll()
    setAllCompanies(response.data)
    setLoading(false)
  }

  const setParametersUserEdit = (name, value) => {
    setCompanyEdit(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleFormChange = e => {
    const { name, value } = e.target;
    setParametersUserEdit(name, value)
    setButtonStatus(formvalidade)
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true)
    if (companyEdit._id)
      await CompanyService.edit(companyEdit)
    else
      try {
        await axios.post(`${process.env.REACT_APP_API}/api/v1/company`, companyEdit)
          .then(toast.info('Empresa sendo criada!', {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }))
          .then(response => {
            toast.success('Empresa criada com sucesso!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

          })
        await GetAllCompanies()
      } catch (errors) {
        toast.error(`${errors.response.data.errors}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    await GetAllCompanies()
    setLoading(false)
  }

  useEffect(() => {
    GetAllCompanies();
  }, [])


  let permission = false;
  const user = JSON.parse(localStorage.getItem('user'));
  permission = user['company_id'] === '625053b7cb5d75f46e02f052';

  const editCompany = (company) => {
    setCompanyEdit(company)

  }

  async function deleteCompany(id) {
    setLoading(true)
    await CompanyService.delete(id);
    GetAllCompanies()
  }

  const GetUsers = async (id) => {
    setLoadingDelete(true)
    setCompanyEdit(id)
    const response = await UserService.getUsersByCompanyId(id)
    setUsers(response.data)
    setLoadingDelete(false)
  }

  function formvalidade() {
    if (companyEdit.CNPJ.length < 17)
      return setButtonStatus(true)
    else
      setButtonStatus(false)
  }

  console.log(companyEdit.CNPJ)

  return (
    <div>
      <Navbar warning={false} />
      {permission ?
        <div className='container-fluid'>
          <div className='text-center'>
            {loading ? <CircularProgress className='mt-5' /> :
              <div className='row d-flex justify-content-end '>
                <div className='col-auto mt-2 mb-2'>
                  <Button size="large" variant="contained" type="submit" className="btn btn-primary" data-toggle="modal" data-target="#formCompany" onClick={() => setCompanyEdit({ name: "", CNPJ: "" })}>Criar Empresa</Button>
                </div>

                <table className="table text-left">
                  <thead>
                    <tr>
                      <th scope="col">Nome</th>
                      <th scope="col">CNPJ</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCompanies.map((company) => {
                      return (
                        <tr key={company._id}>
                          <td>{company.name}</td>
                          <td>{company.CNPJ}</td>
                          <td className='d-flex justify-content-end pr-5'>
                            <button className="btn btn-outline-primary mr-2" data-toggle="modal" data-target="#formCompany" onClick={() => editCompany(company)}><i className="fas fa-user-edit"></i></button>
                            <button className="btn btn-outline-danger" data-toggle="modal" data-target="#DeleteCompany" onClick={() => GetUsers(company._id)}><i className="fas fa-trash"></i></button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>

              </div>
            }
          </div>
        </div>
        :
        <></>
      }
      <div className="modal fade" id="formCompany" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{companyEdit._id ? "Editar Empresa" : "Cadastro de Empresa"}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="form-group row">
                  <div className="input-group mb-3 col-sm-6">
                    <Box
                      className="col-form-label col-sm-12"
                      component="form"
                      noValidate
                      autoComplete="off">
                      <TextField
                        className="form-control"
                        id="standard-helperText"
                        label="Name"
                        variant="outlined"
                        value={companyEdit.name}
                        name="name"
                        onChange={e => handleFormChange(e)}
                      />
                    </Box>
                  </div>

                  <div className="input-group mb-3 col-sm-6">
                    <Box
                      className="col-form-label col-sm-12"
                      component="form"
                      noValidate
                      autoComplete="off">
                      <InputMask
                        mask="99.999.999/9999-99"
                        maskChar={false}
                        className="form-control p-textfield"
                        id="standard-helperText"
                        placeholder="CNPJ"
                        variant="outlined"
                        value={companyEdit.CNPJ}
                        name="CNPJ"
                        onChange={e => handleFormChange(e)}
                      />
                    </Box>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Fechar</button>
                <button type="button" className="btn btn-outline-primary" disabled={buttonStatus} onClick={handleSubmit} data-dismiss="modal">{companyEdit._id ? "Editar Empresa" : "Cadastrar Empresa"}</button>
              </div>
            </form>
          </div>
        </div>
      </div >

      <div class="modal fade" id="DeleteCompany" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Excluir Empresa</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className='text-center'>
              {loadingDelete ? <CircularProgress className='m-5' /> :
                <>
                  {users.length > 0 ?
                    <div className='text-left'>
                      <div>
                        <Alert severity="warning">Aviso! Não é possivel excluir essa empresa, pois existem usuários vinculados a ela.</Alert>
                      </div>
                      <button class="btn btn-primary m-3" type="button" data-toggle="collapse" data-target="#listUsers" aria-expanded="false" aria-controls="collapseExample">
                        Veja aqui os usuários
                      </button>
                      <div class="collapse" id="listUsers">
                        <div class="card card-body">
                          <table className="table text-left">
                            <thead>
                              <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">Email</th>
                              </tr>
                            </thead>
                            <tbody>
                              {users.map((users) => {
                                return (
                                  <tr key={users._id}>
                                    <td>{users.name}</td>
                                    <td>{users.email}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    :
                    <div class="modal-body">
                      Você tem certeza que deseja excluir essa empresa?
                    </div>
                  }

                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-success" data-dismiss="modal" disabled={users.length > 0} onClick={() => deleteCompany(companyEdit)}>Confirmar</button>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div >
  )
}



