import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import './videos.css'
import videoService from '../../services/videoService';
import VideoCard from '../../components/VideoCard';
import { getJson } from '../../services/requests';
import Navbar from '../../components/Navbar';
import { CircularProgress } from '@mui/material';

export default function Videos() {

    const [videosBySearch, setVideosBySearch] = useState([]);
    const [searchedText, setSearchedText] = useState('');
    const [categoryWithVideos, setCategoryWithVideos] = useState([]);
    const [Videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false)

    const { category_id } = useParams()

    useEffect(async () => {
        setLoading(true)
        const response = await getJson(`api/v1/video/by-category/${category_id}`)
        setCategoryWithVideos(response.data)
        setVideos(response.data.videos)
        setLoading(false)
    }, []);

    async function searchItem(text) {
        setLoading(true)
        setSearchedText(text)
        const response = await videoService.search(text, category_id)
        setVideosBySearch(response.data)
        setLoading(false)
    }

    return (
        <div>
            <Navbar className="sticky-top" warning={false} />
            <div className='container-fluid'>
                <div className="d-flex p-5 justify-content-center">
                    <a href="/">
                        <button type="button" className="btn btn-outline-primary justify-content-center mr-3"><i class="fas fa-undo-alt" /></button>
                    </a>
                    <input className="form-control input-pesquisar w-50" type="search" onChange={(e) => searchItem(e.target.value)} placeholder="Busque aqui a informação que deseja encontrar no DATI" aria-label="Search" />
                </div>
            </div>
            <div className='container-fluid'>
                <div className='d-flex justify-content-center'>{loading ? <CircularProgress /> :
                    <>
                        {searchedText === '' ?
                            <div className='row d-lg-flex justify-content-center '>
                                {Object.keys(Videos).length == 0 ?
                                    <div className="d-flex justify-content-center">
                                        <div className='col-4 text-left d-lg-block d-xl-block d-none pl-5'>
                                            <h1 className='text-color-blue-default'>Ooops.</h1>
                                            <h4 className='text-color-green'>Não encontramos uma solução relacionada a sua busca.</h4>
                                            <span className='text-color-blue-default'>Parece que não conseguimos relacionar sua busca com uma das nossa soluções tecnológicas para o comércio exterior. Não se preocupe, você pode enviar uma mensagem para a nossa equipe comercial  e vamos entrar em contato com você para entender qual a funcionalidade que você não encontrou. Você pode falar conosco através no email atendimento@godati.com.br e no whatsapp +55 11-5084-8422.</span>
                                        </div>
                                        <div className='col-lg-4'>
                                            <img src={`${process.env.REACT_APP_URL}/dist/semvideos/semvideo.png`} className="rounded img-fluid pr-5 pb-5 pl-5" alt="Logo" />
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className="sidebar text-white p-0 col-lg-3" style={{ background: categoryWithVideos.background }}>
                                            <div id={categoryWithVideos._id} className='sidebar-sticky pt-4 text-center'>
                                                <div className='d-flex justify-content-center align-items-center m-auto br' style={{ background: categoryWithVideos.icon_background }}>
                                                    <img className='img-fluid' src={categoryWithVideos.icon_url} width="40" height="30"></img>
                                                </div>
                                                <p className='mt-4'>{categoryWithVideos.name}</p>
                                                <span className='text-center'>
                                                    {categoryWithVideos.description}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='col-lg-9 margin-moba'>
                                            <div className='d-flex row'>
                                                {Videos.map(item =>
                                                    <div className="col-lg-4">
                                                        <a href={`/videos/${item._id}/by-video`}>
                                                            <VideoCard video={item} />
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>

                            :
                            <div>
                                {Object.keys(videosBySearch).length == 0 ?
                                    <div className="d-flex justify-content-center">
                                        <div className='col-4 text-left d-lg-block d-xl-block d-none pl-5'>
                                            <h1 className='text-color-blue-default'>Ooops.</h1>
                                            <h4 className='text-color-green'>Não encontramos uma solução relacionada a sua busca.</h4>
                                            <span className='text-color-blue-default'>Parece que não conseguimos relacionar o texto digitado com uma das nossa soluções tecnológicas para o comércio exterior. Não se preocupe, você pode enviar uma mensagem para a nossa equipe comercial  e vamos entrar em contato com você para entender qual a funcionalidade que você não encontrou. Você pode falar conosco através no email atendimento@godati.com.br e no whatsapp +55 11-5084-8422.</span>
                                        </div>
                                        <div className='col-lg-4'>
                                            <img src={`${process.env.REACT_APP_URL}/dist/busca/404.png`} className="rounded img-fluid pr-5 pb-5 pl-5" alt="Logo" />
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <h2 className='text-center'>Seguem os videos sugeridos para sua busca:</h2> <br></br>
                                        {
                                            videosBySearch.map(item =>
                                                <div className='container-fluid'>
                                                    <div className="row">
                                                        <div className="col-12 mb-5">
                                                            <a href={`/videos/${item._id}/by-video`}>
                                                                <VideoCard video={item} />
                                                            </a>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </>
                }
                </div>
            </div>
        </div>
    )
}
