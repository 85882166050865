import Avisos from "./pages/Avisos";
import Categories from "./pages/Categories";
import Videos from "./pages/Videos";
import VideoDetail from "./pages/Videos/VideosDetail";
import Register from "./pages/Register";
import Review from "./pages/Review";
import Import from "./pages/Importacao";
import CompanyRegister from "./pages/Company";

export const Pages = [

    {
        path: "/avisos",
        element: <Avisos />,
        exact: true,
        name: "Avisos",
        icon: "far fa-question-circle",
        admin: false
    },
    {
        path: "/",
        element: <Categories />,
        exact: true,
        name: "Categorias de Vídeo",
        icon: "far fa-play-circle",
        admin: false

    },
    {
        path: "/videos/:category_id/by-category",
        element: <Videos />,
        exact: true
    },

    {
        path: "/videos/:video_id/by-video",
        element: <VideoDetail />,
        exact: true
    },

    {
        path: "/register",
        element: <Register />,
        exact: true,
        name: "Registro de Usuário",
        icon: "fas fa-user-plus",
        admin: true

    },
    {
        path: "/Review",
        element: <Review />,
        exact: true,
        name: "Relatórios de avaliação",
        icon: "fas fa-chart-bar",
        admin: true

    },
    {
        path: "/companyRegister",
        element: <CompanyRegister />,
        exact: true,
        name: "Registro de Empresa",
        icon: "far fa-building",
        admin: true
    },
    {
        path: "/import",
        element: <Import />,
        exact: true,
        name: "Importar planilha",
        icon: "far fa-file-excel",
        admin: true
    }
]