import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter, Routes } from "react-router-dom";
import NotFound from './components/NotFound';
import Loading from './components/Loading';
import { Pages } from './Pages';
import Login from './pages/Login';
import UserService from './services/userService';
import './App.css'

export default function App() {
  const [allowed, setAllowed] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    UserService.verifyIfTokenIsValid().then(response => {
      setAllowed(response)
      setLoading(false)
    })
  })

  return (
    <>
      {loading ?
        <Loading /> :
        <>
          {!allowed ?
            <Login />
            :
            <BrowserRouter>
              <Routes>
                {Pages.map((route, index) => (
                  <Route key={index} {...route} />
                ))}

                <Route path='*' element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          }
        </>
      }
    </>
  );
}