import { IconButton } from "@mui/material";
import React, { useState } from 'react';
import "./dislike.css"
import videoReviewService from "../../../services/videoReviewService";
import dislike from "../images/dislike.png";
import triste from "../images/triste.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Dislike(props) {
    const { value, type } = props;
    const action = 'dislike'
    const [message, setMessage] = useState(false);

    const notify = () => toast.error(
        <div id="dislike" className="text-justify">
            <div className="text-center">
                <h2>Desculpa!<img width="80px" src={triste} /></h2>
            </div>
            <p>Entendemos que este video não te ajudou e essa informação é importante para nós. Aqui na Dati nossa missão é facilitar as suas rotinas e por isso compartilhamos com o nosso time técnico e de atendimento ao cliente a sua insatisfação com este video.<br></br>
                Em até 24 horas úteis vamos entrar em contato com você para entender em qual ponto o video não ajudou. Caso precise de um atendimento neste momento, orientamos  que entre em contato com a equipe de Customer Success ou você também pode falar conosco através no email atendimento@godati.com.br e no whatsapp  +55 11-5084-8422.</p>
        </div>, {
        position: "bottom-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleClick = async (e) => {
        const response = await videoReviewService.create(value, action, type)
        setMessage(true)
        notify()
    }
    return (
        <div>
            {!message ?
                <IconButton aria-label="Dislike" onClick={(e) => handleClick(e)}>
                    <img src={dislike} alt="dislike" />
                </IconButton>
                :
                <>
                    <IconButton aria-label="Dislike" onClick={(e) => handleClick(e)}>
                        <img src={dislike} alt="dislike" />
                    </IconButton>
                    <div>
                        <ToastContainer
                            position="bottom-center"
                            autoClose={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                        />
                    </div>

                </>
            }
        </div>
    )
}
