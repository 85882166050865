import React, { useState, useEffect } from 'react';
import UserService from '../../services/userService';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Navbar from '../../components/Navbar';
import FormControlLabel from '@mui/material/FormControlLabel';
import CompanyService from '../../services/companyService';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import { deletar, get, post } from '../../services/requests';
import { Button, CircularProgress } from '@mui/material';
import videoReviewService from '../../services/videoReviewService';
import { ToastContainer } from 'react-toastify';



export default function Register() {

    const [buttonStatus, setButtonStatus] = useState();
    const [dateStatus, setDateStatus] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);
    const [username, setUsername] = useState("");
    const [company, setCompany] = useState("");
    const [filtered, setFiltered] = useState(false);
    const [filteredSearch, setFilteredSearch] = useState([]);
    const [userEdit, setUserEdit] = useState({});
    const [loading, setLoading] = useState(false)

    const handleFormChange = e => {
        const { name, value } = e.target;
        setParametersUserEdit(name, value)
        setButtonStatus(formValidation)
    };

    const setParametersUserEdit = (name, value) => {
        setUserEdit(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const GetAllUsers = async e => {
        setLoading(true)
        const response = await UserService.getAll()
        setAllUsers(response.data)
        setLoading(false)
    }

    const GetAllCompanies = async e => {
        const response = await CompanyService.getAll()
        setAllCompanies(response.data)
    }


    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true)
        if (userEdit._id)
            await UserService.edit(userEdit)
        else
            await UserService.create(userEdit)

        await GetAllUsers()
        setLoading(false)
    }

    function dateHandler() {
        if (dateStatus) {
            setDateStatus(false)
        } else {
            setDateStatus(true)
        }
    }

    const handleShowPassword = () => {
        if (showPassword) {
            setShowPassword(false)
        } else {
            setShowPassword(true)
        }
    }

    const handleNewPassword = () => {
        let pwd = Math.random(30).toString(36).slice(-11)
        setParametersUserEdit("password", pwd)
    }

    useEffect(() => {
        GetAllUsers();
        GetAllCompanies();
    }, [])

    function formValidation() {
        if (!userEdit.name || !userEdit.email || !userEdit.password) {
            return true
        } else if (typeof userEdit.name !== "undefined" || typeof userEdit.password !== "undefined") {
            if (!userEdit.name.match(/^[a-zA-Z]+$/)) {
                return true
            }
        } else if (typeof userEdit.email !== 'undefined') {
            let lastAtPos = userEdit.email.lastIndexOf("@");
            let lastDotPos = userEdit.email.lastIndexOf(".");

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && userEdit.email.indexOf("@@") == -1 && lastDotPos > 2 && userEdit.email.length - lastDotPos > 2)) {
                return true
            }
        } else {
            return false
        }
    }

    let permission = false;
    const user = JSON.parse(localStorage.getItem('user'));
    permission = user['company_id'] === '625053b7cb5d75f46e02f052';

    async function deleteUser(id) {
        try {
            setLoading(true)
            await UserService.deleteUser(id);
            GetAllUsers()
        } catch {
            setLoading(false)
        }
    }



    const editUser = (user) => {
        setUserEdit(user)
    }

    const handleChangeSetUsername = (event) => {
        setUsername(event.target.value);
    };

    const handleChangeSetCompany = (event) => {
        setCompany(event.target.value);
    };

    const filterSearch = async (e) => {
        e.preventDefault();
        setLoading(true)
        const response = await UserService.filter(username, company)
        setFilteredSearch(response.data)
        setFiltered(true)
        setLoading(false)
    }

    return (
        <div>
            <Navbar warning={false} />
            {permission ?
                <div className='container-fluid'>
                    <div className="row d-flex justify-content-center">
                        <Box className="col-auto mt-3 mb-3">
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">Nome de usuário</InputLabel>
                                <Select
                                    sx={{ display: "inline-flex", width: 200 }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="username"
                                    value={username}
                                    onChange={handleChangeSetUsername}
                                >
                                    <MenuItem value={0}>Todos</MenuItem>
                                    {allUsers.map((username) => {
                                        return (<MenuItem value={username._id}>{username.name}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className="col-auto mt-3 mb-3">
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
                                <Select
                                    sx={{ display: "inline-flex", width: 200 }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Company"
                                    value={company}
                                    onChange={handleChangeSetCompany}
                                >
                                    <MenuItem value={0}>Todos</MenuItem>
                                    {allCompanies.map((company) => {
                                        return (<MenuItem value={company._id}>{company.name}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <div className="col-auto mt-3 mb-3">
                            <Button size="large" variant="contained" onClick={filterSearch} type="submit" className="btn btn-primary mt-2">Filtrar</Button>
                        </div>
                        <div className='col-auto mt-4'>
                            <Button data-toggle="modal" data-target="#formUser" size="large" variant="contained" type="submit" className="btn btn-primary" onClick={() => setUserEdit({ name: "", email: "", expired_at: "", company_id: null, password: "" })}>Criar usuário</Button>
                        </div>
                    </div>

                    <div className='vh-100 tab-content table-responsive text-center m-2'>
                        {loading ? <CircularProgress className='mt-5' /> :
                            <table className="table table-hover text-left">
                                <thead>
                                    <tr>
                                        <th scope="col">Usuário</th>
                                        <th scope="col">E-mail</th>
                                        <th scope="col">Empresa</th>
                                        <th scope="col">Data de expiração</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!filtered ?
                                        <>
                                            {Object.keys(allUsers).length == 0 ?
                                                <tr>
                                                    <td className="text-center" colSpan="7">Nenhum registro encontrado</td>
                                                </tr>
                                                :
                                                <>
                                                    {
                                                        allUsers.map((user) => {
                                                            let date = new Date(user.expired_at)

                                                            return (
                                                                <tr key={user._id}>
                                                                    <td>{user.name}</td>
                                                                    <td>{user.email}</td>
                                                                    <td>{user.company ? user.company.name : null}</td>
                                                                    <td>{user.expired_at ? (date.getDate() + 1).toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear() : null}</td>
                                                                    <td><button className="btn btn-outline-primary mr-2" data-toggle="modal" data-target="#formUser" onClick={() => editUser(user)}><i className="fas fa-user-edit"></i></button>
                                                                        <button className="btn btn-outline-danger" data-toggle="modal" data-target="#deleteUsuario" onClick={() => setUserEdit(user._id)}><i className="fas fa-trash"></i></button></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </>
                                            }

                                        </>
                                        :
                                        <>
                                            {filteredSearch.length == 0 ?
                                                <tr>
                                                    <td className="text-center" colSpan="7">Nenhum registro encontrado</td>
                                                </tr>
                                                :
                                                <>
                                                    {filteredSearch.map((search) => {
                                                        let date = new Date(search.expired_at)

                                                        return (
                                                            <tr className="table" key={search._id}>
                                                                <td>{!search ? null : search.name}</td>
                                                                <td>{search.email}</td>
                                                                <td>{!search.company ? null : search.company.name}</td>
                                                                <td>{search.expired_at ? (date.getDate() + 1).toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear() : null}</td>
                                                                <td><button className="btn btn-outline-primary mr-2" data-toggle="modal" data-target="#formUser" onClick={() => editUser(search)}><i className="fas fa-user-edit"></i></button>
                                                                    <button className="btn btn-outline-danger" onClick={() => deleteUser(search._id)}><i className="fas fa-trash"></i></button></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        }
                    </div>

                </div>

                :

                <div className="text-center mt-5">
                    <h1 className='mt-5'>VOCÊ NÃO TEM PERMISSÃO PARA ACESSAR ESSA PÁGINA</h1>
                </div>

            }
            <div className="modal fade" id="formUser" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{userEdit._id ? "Editar usuário" : "Criar usuário"}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form>
                            <div className="modal-body">
                                <div className="form-group row">
                                    <div className="input-group mb-3 col-sm-6">
                                        <Box
                                            className="col-form-label col-sm-12"
                                            component="form"
                                            noValidate
                                            autoComplete="off">
                                            <TextField
                                                className="form-control"
                                                id="standard-helperText"
                                                label="Username"
                                                value={userEdit.name}
                                                name="name"
                                                variant="outlined"
                                                onChange={e => handleFormChange(e)}
                                            />
                                        </Box>
                                    </div>

                                    <div className="input-group mb-3 col-sm-6">
                                        <Box
                                            className="col-form-label col-sm-12"
                                            component="form"
                                            noValidate
                                            autoComplete="off">
                                            <TextField
                                                className="form-control"
                                                id="standard-helperText"
                                                type="email"
                                                label="E-mail"
                                                value={userEdit.email}
                                                name="email"
                                                variant="outlined"
                                                onChange={e => handleFormChange(e)}
                                            />
                                        </Box>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="input-group mb-3 col-sm-6">
                                        <Box
                                            className="col-form-label col-sm-12"
                                            component="form"
                                            noValidate
                                            autoComplete="off">
                                            <TextField
                                                className="form-control"
                                                id="standard-helperText"
                                                type={showPassword ? 'text' : 'password'}
                                                label="Senha"
                                                value={userEdit.password}
                                                name="password"
                                                variant="outlined"
                                                onChange={e => handleFormChange(e)}
                                            />
                                        </Box>
                                        <div className='col-form-label col-sm-12 container'>
                                            <small style={{ color: "red" }}>* A senha deve conter no mínimo 8 caracteres.</small>
                                            <div className="btn-toolbar">
                                                <div className="btn-group mt-2 mr-2">
                                                    <button type="button" className="btn btn-outline-primary" onClick={handleNewPassword}>Gerar nova senha</button>
                                                </div>
                                                <div className="btn-group mt-2 mr-2">
                                                    <button type="button" className="btn btn-outline-secondary" onClick={handleShowPassword}>Visualizar senha</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3 col-sm-6">
                                        <Box
                                            className="col-form-label col-sm-12"
                                            component="form"
                                            noValidate
                                            autoComplete="off">
                                            <TextField
                                                className="form-control"
                                                type="text"
                                                onFocus={e => e.target.type = 'date'}
                                                onBlur={e => e.target.type = (userEdit.expired_at ? 'date' : 'text')}
                                                label="Data de expiração"
                                                value={userEdit.expired_at}
                                                name="expired_at"
                                                variant="outlined"
                                                onChange={e => handleFormChange(e)}
                                                disabled={dateStatus}
                                            />
                                        </Box>
                                        <div className="col-form-label col-sm-12">
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox defaultNotChecked onClick={dateHandler} />} label="Não expira" />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3 col-sm-6">
                                        <Box className="col-lg-12">
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={userEdit.company_id}
                                                    name="company_id"
                                                    label="Company"
                                                    onChange={e => handleFormChange(e)}
                                                >
                                                    {allCompanies.map((company) => {
                                                        return (<MenuItem value={company._id}>{company.name}</MenuItem>)
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Fechar</button>
                                <button type="button" className="btn btn-outline-primary" onClick={handleSubmit} disabled={buttonStatus} data-dismiss="modal">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >

            <div class="modal fade" id="deleteUsuario" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Excluir Usuário</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className='text-center'>
                            <div class="modal-body">
                                Você tem certeza que deseja excluir esse usuário?
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
                                <button type="button" class="btn btn-success" data-dismiss="modal" onClick={() => deleteUser(userEdit)}>Confirmar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div >
    )
}
