import Review from "../pages/Review"
import { get, post, getJson } from "./requests"

const videoReviewService = {
    filter: async function (data) {
    
    let params = `&user=${data.user}&type=${data.type}&action=${data.action}&video=${data.video}&start_date=${data.start_date}&end_date=${data.end_date}`
        const response = get("api/v1/review/filter", null, params)
            .then(response => {
                return response.json().then(responseTypeFilter => ({ responseTypeFilter }))
            })
        return response
    },

    create: async function (value, action, type) {
        try {
            let review = {};
            if (type == 'video') {
                review = {
                    video_id: value,
                    action: action,
                    type: type
                };
            } else {
                review = {
                    value: value,
                    action: action,
                    type: type
                };
            }

            const response = await post('api/v1/review', review)
        } catch (err) {
            throw new Error(err)
        }
    },

    getAll: async function () {
        return await getJson("api/v1/review")
    }
}
export default videoReviewService