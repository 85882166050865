import React, { useEffect, useState } from 'react';
import './categories.css';
import { getJson } from '../../services/requests';
import videoService from '../../services/videoService';
import VideoCard from '../../components/VideoCard';
import Navbar from '../../components/Navbar';
import Avisos from '../Avisos';
import Like from '../../components/LikeDislike/Like';
import Dislike from '../../components/LikeDislike/Dislike';
import { CircularProgress } from '@mui/material';

export default function Categories() {

    const [videosBySearch, setVideosBySearch] = useState([]);
    const [searchedText, setSearchedText] = useState('');
    const [category, setCategory] = useState([]);
    const [buttonStatus, setButtonStatus] = useState("btn btn-outline-primary justify-content-center mr-3 fade");
    const [loading, setLoading] = useState(false)

    useEffect(async () => {
        setLoading(true)
        const response = await getJson(`api/v1/category`)
        setCategory(response.data)
        setLoading(false)
    }, []);


    useEffect(() => {
        if (searchedText == '') {
            let time = 1000
            if (category.length > 0) {
                category.map(item => {
                    setTimeout(() => {
                        let element = document.getElementById(`card-effect-${item._id}`)
                        element.classList.remove('d-none')
                        element.classList.add('slideUp1')
                    }, time)
                    time += 1000
                })
            }
        }
    });

    async function searchItem(text) {
        setLoading(true)
        if (text.length >= 3) {
            const response = await videoService.search(text)
            setVideosBySearch(response.data)
            setLoading(false)
        }

        setSearchedText(text)
        setLoading(false)
        if (text === 'undefined') {
            setButtonStatus("btn btn-outline-primary justify-content-center mr-3 fade");

        } else {
            setButtonStatus("btn btn-outline-primary justify-content-center mr-3");

        }
    }

    const user = JSON.parse(localStorage.getItem('user'));
    const warning = user['warning'];


    return (
        <>
            {warning ?
                <div id="page-categories">
                    <>
                        <Navbar warning={false} />
                        {searchedText.length < 3 ?
                            <>
                                <div className='d-flex justify-content-center m-5'>
                                    <div className='col-1' />
                                    <div className='d-flex align-items-center col-6 p-auto m-4 '>
                                        <input className="form-control input-pesquisar" type="search" onChange={(e) => searchItem(e.target.value)} placeholder="Busque aqui a informação que deseja encontrar no DATI" aria-label="Search" />
                                    </div>
                                    <div className='col-1' />
                                </div>
                                <div className='text-center'>
                                    {loading ? <CircularProgress className='m-5' /> :
                                        <>
                                            <div className="text-white text-center mt-5">
                                                {category.map(item =>
                                                    <div id={`card-effect-${item._id}`} className='card-effect-container pt-4 d-none text-center vh-100 height' style={{ background: item.background }}>
                                                        <div className='d-flex justify-content-center align-items-center m-auto br' style={{ background: item.icon_background }}>
                                                            <a href={`/videos/${item._id}/by-category`}>
                                                                <img className='img-fluid' src={item.icon_url} width="40" height="30"></img>
                                                            </a>
                                                        </div>
                                                        <p className='mt-4'>{item.name}</p>
                                                        <span className='text-center'>
                                                            {item.description}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    }
                                </div>
                            </>
                            :
                            <>
                                <div className='d-flex justify-content-center m-5'>
                                    <div className='col-1' />
                                    <div className='d-flex align-items-center mt-1 col-6'>
                                        <input className="form-control input-pesquisar" type="search" onChange={(e) => searchItem(e.target.value)} placeholder="Busque aqui a informação que deseja encontrar no DATI" aria-label="Search" />
                                    </div>
                                    <div className='col-1 text-center'>
                                        <div className="d-flex justify-content-center flex-row-reverse pt-2 align-items-center">
                                            <Dislike value={searchedText} type="search" />
                                            <Like value={searchedText} type="search" />
                                        </div>
                                        <h5>Essa busca te ajudou?</h5>
                                    </div>
                                </div>
                                <div className='text-center'>
                                    {loading ? <CircularProgress /> :
                                        <>
                                            {Object.keys(videosBySearch).length == 0 ?
                                                <div className="d-flex justify-content-center">
                                                    <div className='col-4 text-left d-lg-block d-xl-block d-none pl-5'>
                                                        <h1 className='text-color-blue-default'>Ooops.</h1>
                                                        <h4 className='text-color-green'>Não encontramos uma solução relacionada a sua busca.</h4>
                                                        <span className='text-color-blue-default'>Parece que não conseguimos relacionar o texto digitado com uma das nossa soluções tecnológicas para o comércio exterior. Não se preocupe, você pode enviar uma mensagem para a nossa equipe comercial  e vamos entrar em contato com você para entender qual a funcionalidade que você não encontrou. Você pode falar conosco através no email atendimento@godati.com.br e no whatsapp +55 11-5084-8422.</span>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <img src={`${process.env.REACT_APP_URL}/dist/busca/404.png`} className="rounded img-fluid pr-5 pb-5 pl-5" alt="Logo" />
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <h2 className='text-center'>Seguem os videos sugeridos para sua busca:</h2> <br></br>
                                                    <div className='container-fluid'>
                                                        <div className="row">
                                                            {videosBySearch.map(item =>
                                                                <div className="col-lg-4">
                                                                    <a href={`/videos/${item._id}/by-video`}>
                                                                        <VideoCard video={item} />
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </>
                </div>
                :
                <Avisos />
            }
        </>
    )
}