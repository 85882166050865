import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Pages } from "../../Pages";
import { Button } from "@mui/material";

export default function MenuDrawer() {
    const [state, setState] = React.useState({
        right: false,
    });

    function logout() {
        localStorage.clear()
        window.location.reload()
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    let permission = false;
    const user = JSON.parse(localStorage.getItem('user'));

    if (user['company_id'] === '625053b7cb5d75f46e02f052') {
        permission = true;
    } else {
        permission = false;
    }
    const list = (anchor, user) => (

        <Box
            sx={{ 'auto': 750, 'width': 325, color: '#253f78', overflow: 'hidden' }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem>
                    <ListItemText primary="dati+" />
                </ListItem>
                <ListItem
                    className="m-2 p-2"
                    sx={{ color: '#253f78' }}>
                    <ListItemIcon
                        sx={{ color: '#253f78' }}
                    >
                        <i className="fas fa-user" />
                    </ListItemIcon>
                    <ListItemText>{user}</ListItemText>
                </ListItem>
            </List>
            <Divider />
            <List>
                {permission ?
                    Pages.map((route, index) => (
                        route.name ?
                            <a href={route.path} className="text-muted text-decoration-none">
                                <ListItem
                                    button key={route.path}
                                    className="m-2 p-2"
                                    sx={{ color: '#253f78' }}
                                >
                                    <ListItemIcon
                                        sx={{ color: '#253f78' }}
                                    >
                                        <i className={route.icon} />
                                    </ListItemIcon>
                                    <ListItemText primary={route.name} />
                                </ListItem>
                            </a>
                            :
                            <></>
                    )) :
                    Pages.map((route, index) => (
                        permission === route.admin ?
                            route.name ?
                                <a href={route.path} className="text-muted text-decoration-none">
                                    <ListItem
                                        button key={route.path}
                                        className="m-2 p-2"
                                        sx={{ color: '#253f78' }}
                                    >
                                        <ListItemIcon
                                            sx={{ color: '#253f78' }}
                                        >
                                            <i className={route.icon} />
                                        </ListItemIcon>
                                        <ListItemText primary={route.name} />
                                    </ListItem>
                                </a>
                                :
                                <></>
                            :
                            <></>
                    ))}
                <Divider />
                <a href="/" onClick={logout} className="text-muted text-decoration-none">
                    <ListItem
                        className="m-2 p-2"
                        sx={{ color: '#253f78', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}>
                        <ListItemIcon
                            sx={{ color: '#253f78' }}
                        >
                            <i className="fas fa-sign-out" />
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                    </ListItem>
                </a>
            </List>
        </Box>
    );

    return (
        <div className='d-flex justify-content-end'>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button
                        sx={{ color: '#fff', backgroundColor: '#253f78', fontSize: 25, '&:hover': { backgroundColor: '#253f78 !important' } }}
                        className="btn btn-outline-primary"
                        onClick={toggleDrawer(anchor, true)}
                    >
                        <i className="fas fa-bars" />
                    </Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor, user['name'])}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
