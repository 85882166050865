import { toast } from "react-toastify"
import HttpResponseCodes from "../helpers/HttpResponseCodes"
import { deletar, get, getJson, post, put } from "./requests"

const UserService = {
    getUserLocalStorage: function () {
        const userLocalStorage = localStorage.getItem("user")
        const initialValue = JSON.parse(userLocalStorage)
        return initialValue || ""
    },

    setUserLocalStorage: function (params) {
        localStorage.setItem("user", JSON.stringify(params))
    },

    signOut: function () {
        localStorage.removeItem("user")
        return true
    },

    getAuth: async function (params) {
        const response = post("oauth/token", params)
            .then(response => {
                return response.json().then(responseAuth => ({ responseAuth }))
            })
        return response
    },

    getUser: function () {
        const response = get("api/v1/user")
            .then(response => {
                return response.json().then(responseUser => ({ responseUser }))
            })
        return response
    },

    signIn: async function (username, password) {
        try {
            const auth = {
                client_id: process.env.REACT_APP_CLIENT_ID,
                client_secret: process.env.REACT_APP_CLIENT_SECRET,
                grant_type: process.env.REACT_APP_GRANT_TYPE,
                username: username,
                password: password,
            }

            const { responseAuth } = await this.getAuth(auth)
            this.setUserLocalStorage({ token: responseAuth.access_token })

            const { responseUser } = await this.getUser()
            const user = this.getUserLocalStorage()

            let storage = responseUser.data
            storage.token = user.token
            this.setUserLocalStorage(storage)

        } catch (err) {
            throw new Error(err)
        }
    },

    verifyIfTokenIsValid: async function () {
        try {
            const response = await get("api/v1/user/verify")

            if (response.status != HttpResponseCodes.OK) {
                localStorage.removeItem("user");
                return false
            }

            return true
        } catch {
            localStorage.removeItem("user")
            return false
        }
    },

    create: async function (user) {
        try {
            const response = await post('api/v1/user', user)
                .then(toast.info('Usuário sendo criado!', {

                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }))
                .then(response => {
                    toast.success('Usuário criado com sucesso!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                })
        } catch (err) {
            toast.error('Algo deu errado!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }
    },

    edit: async function (user) {
        try {
            const response = await put('api/v1/user', user)
                .then(toast.info('Usuário sendo editado!', {

                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }))
                .then(response => {
                    toast.success('Usuário editado com sucesso!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                })
        } catch (err) {
            toast.error('Algo deu errado!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    },

    deleteUser: async function (id) {
        try {
            const response = deletar(`api/v1/user/${id}`)
            return response
                .then(toast.info('Usuário sendo deletado!', {

                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }))
                .then(response => {
                    toast.success('Usuário deletado com sucesso!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                })
        } catch {
            toast.error('Algo deu errado!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    },

    getAll: async function () {
        return await getJson("api/v1/user/all")
    },

    warningNotification: async function () {
        try {
            const params = {
                "warning": true
            }
            console.log(params)
            put("api/v1/user/warning", params)
        } catch (err) {
            throw new Error(err)
        }
    },

    getById: async function (id) {
        return await getJson(`api/v1/user/${id}`)
    },

    filter: async function (username, company) {
        let params = `&user=${username}&company=${company}`
        return await getJson("api/v1/user/filter", null, params)

    },

    getUsersByCompanyId: async function (id) {
        return await getJson(`api/v1/user/get_users_by_company/${id}`)
    }
}

export default UserService