import { IconButton } from "@mui/material";
import React, { useState } from 'react';
import "./Like.css"
import videoReviewService from "../../../services/videoReviewService";
import like from "../images/like.png";
import feliz from "../images/feliz.svg";
import { Alert, Snackbar } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Like(props) {
    const { value, type } = props;
    const action = 'like';
    const [message, setMessage] = useState(false);

    const notify = () => toast.success(
        <div id="like" className="text-justify">
            <div className="text-center">
                <h2>Obrigado !<img width="80px" src={feliz} /></h2>
            </div>
            <p>Ficamos felizes em saber que este vídeo te ajudou, sua  satisfação ajuda a nossa Inteligência artificial a  relacionar os melhores vídeos.</p>
        </div>, {
        position: "bottom-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleClick = async (e) => {
        const response = await videoReviewService.create(value, action, type)
        setMessage(true)
        notify()
    }

    return (
        <div>
            {!message ?
                <IconButton aria-label="Like" onClick={(e) => handleClick(e)}>
                    <img src={like} alt="like" />
                </IconButton>
                :
                <>
                    <>
                        <IconButton aria-label="Like" onClick={(e) => handleClick(e)}>
                            <img src={like} alt="like" />
                        </IconButton>
                    </>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                    />

                </>
            }
        </div>

    )
}
