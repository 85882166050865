import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/';
import "./importacao.css";
import { Button, CircularProgress, Input } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import VideoService from "../../services/videoService";
import axios from "axios";
import UserService from "../../services/userService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Import = () => {
    const [videos, setVideos] = useState([]);
    const [file, setFile] = useState();
    const [category, setCategory] = useState([]);
    const closeButton = React.useRef(null);
    const [loading, setLoading] = useState(false)

    const getAllVideos = async e => {
        setLoading(true)
        const response = await VideoService.getAll();
        setVideos(response.Video.data)
        setLoading(false)
    }

    const downloadFile = async e => {
        window.location.href = `${process.env.REACT_APP_API}/api/v1/upload/export`
    }

    const handleFileSubmit = async () => {
        try {
            setLoading(true)
            let data = new FormData();
            data.append("file_name", file);

            const user = UserService.getUserLocalStorage()

            const header = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
                'Access-Control-Allow-Origin': '*'
            }
            setTimeout(() => {
                closeButton.current.click();
            }, 2000)

            await axios.post(`${process.env.REACT_APP_API}/api/v1/upload/import/`, data, header)
                .then(toast.info('Arquivo sendo importado!', {

                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }))
                .then(response => {
                    toast.success('Arquivo importado com sucesso!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                })
            getAllVideos()
        } catch (err) {
            toast.error('Algo deu errado!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false)
        }
    };

    const handleFile = (file) => {
        setFile(file)
    }

    useEffect(() => {
        getAllVideos();
    }, [])

    const user = JSON.parse(localStorage.getItem('user'));
    const permission = user['company_id'] === '625053b7cb5d75f46e02f052';
    return (
        <><div id='#categories-importacao'>
            <Navbar warning={false} />
            {permission ?
                <div className="container col-lg-12 text-center">
                    {loading ? <CircularProgress className='mt-5' /> :
                        <div className="row">
                            <div className="col-lg-2">
                                <List>
                                    <ListItem
                                        className="m-1 p-1"
                                        sx={{ color: '#253f78' }}>
                                        <button type="button" className="btn btn-outline-primary p-2 col-lg-6" data-toggle='modal' data-target="#exampleModal"><i className="fas fa-file-excel" /> Importar</button>
                                    </ListItem>
                                    <ListItem
                                        className="m-1 p-1"
                                        sx={{ color: '#253f78' }}>
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary p-2 col-lg-6"
                                            onClick={downloadFile}
                                        >
                                            <i className="fas fa-file-export" /> Exportar</button>
                                    </ListItem>
                                </List>
                            </div>
                            <div className="col-sm-10 table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr className="text-center">
                                            <th scope="col">Categoria</th>
                                            <th scope="col">Título</th>
                                            <th scope="col">Subtítulo</th>
                                            <th scope="col">Link</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center tableBody">
                                        {videos.map((video) => (
                                            <tr key={video._id}>
                                                <td>{video.category.identificador}-{video.category.name}</td>
                                                <td>{video.title}</td>
                                                <td>{video.subtitle}</td>
                                                <td><a href={video.link}>{video.link}</a></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    }
                </div>
                :
                <></>
            }
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Importar Planilha</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={closeButton}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form>
                            <div className="d-flex flex-column justify-content-center">
                                <h6 className='p-2 align-self-center'>VAMOS COMEÇAR?</h6>
                                <h1 className='p-2 align-self-center mb-1' id="info">Escolha seu arquivo</h1>
                                <p className='p-2 align-self-center mb-1 directions'>Para importar seus dados do seu computador</p>
                                <p className='p-2 align-self-center mb-1 directions'>ele deve ser um arquivo do tipo <b>xls, xlsx, csv.</b></p>
                                <Input
                                    type="file"
                                    accept="*/*"
                                    style={{ display: 'none' }}
                                    id="contained-button-file"
                                    onChange={file => { handleFile(file.target.files[0]) }}
                                />
                                <label htmlFor="contained-button-file" className="align-self-center">
                                    <Button variant="contained" component="span" className="btn btn-outline-primary col-md-12 align-self-center mb-1">{file && typeof file.name !== 'undefined' ? file.name : "Importar Arquivo"}</Button>
                                </label>
                            </div>
                            <div className="modal-footer">
                                <Button
                                    variant="contained"
                                    type="button"
                                    className="btn btn-outline-primary col-md-2 align-self-center mb-1"
                                    onClick={handleFileSubmit}
                                >Enviar</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
            <ToastContainer />
        </div>
        </>
    )
}

export default Import