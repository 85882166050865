import videoService from "../../services/videoService";
import './VideoCardDetail.css';
export default function VideoCardDetail(props) {
    const item = props.video;

    return (
        <div id={item._id} className="video-card mb-3 border-0">
            <div id="videoCardDetail-css">
                <div className="video-card-body">
                    <h4 className='text-center' >{item.title}</h4>
                    <h6 className='text-center'>{item.subtitle}</h6>
                </div>
                <div className="embed-responsive embed-responsive-16by9 mb-2">
                    <iframe className="embed-responsive-item" src={`https://www.youtube.com/embed/${videoService.getVideoIdByLink(item.link)}?autoplay=1`} allowfullscreen={'allowfullscreen'}></iframe>
                </div>
                <div className="video-card-body">
                    <p className='text-center'> <img src={`${process.env.REACT_APP_URL}/dist/icons/cronometro.png`} height='25px'></img> {item.duration} </p>
                </div>
            </div>
        </div>

    )
}