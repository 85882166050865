import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import videoReviewService from '../../services/videoReviewService';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import UserService from '../../services/userService';
import CompanyService from '../../services/companyService';
import { Button } from '@mui/material';
import videoService from '../../services/videoService';
import "./review.css";
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CircularProgress } from '@mui/material';


export default function Review() {
    const date = new Date;
    const [allReviews, setAllReviews] = useState([]);
    const [allFilteredReviews, setAllFilteredReviews] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [allVideos, setAllVideos] = useState([]);
    const [username, setUsername] = useState([]);
    const [video, setVideo] = useState([]);
    const [company, setCompany] = useState([]);
    const [action, setAction] = useState([]);
    const [type, setType] = useState([]);
    const [startDate, setStartDate] = useState([]);
    const [endDate, setEndDate] = useState([]);
    const [filtered, setFiltered] = useState(false);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setFilteredDates();
        getAllVideos();
        getAllUsers();
        getAllCompanies();
        getAllReviews();
    }, [])

    const setFilteredDates = () => {
        setEndDate(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
        let dateStart = reduceDays(date, 30)
        setStartDate(`${dateStart.getFullYear()}-${dateStart.getMonth() + 1}-${dateStart.getDate()}`)
    }

    const reduceDays = (date, days) => {
        return new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
    }

    const getAllReviews = async e => {
        setLoading(true)
        const response = await videoReviewService.getAll()
        setAllReviews(response.data)
        setAllFilteredReviews(response.data)
        setLoading(false)
    }


    const getAllUsers = async e => {
        const response = await UserService.getAll()
        setAllUsers(response.data)
    }


    const getAllCompanies = async e => {
        const response = await CompanyService.getAll()
        setAllCompanies(response.data)
    }

    const getAllVideos = async e => {
        const response = await videoService.getAll()
        setAllVideos(response.Video.data)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        let data = {
            type: type,
            action: action,
            user: typeof username === "undefined" ? "" : username,
            // company: typeof company === "undefined" ? "" : company,
            video: typeof video === "undefined" ? "" : video,
            start_date: typeof startDate === "undefined" ? "" : startDate,
            end_date: typeof endDate === "undefined" ? "" : endDate,
        }

        const response = await videoReviewService.filter(data)
        setFiltered(true)
        setAllFilteredReviews(response.responseTypeFilter.data)
        setLoading(false)
    }

    const handleChangeSetType = (event) => {
        setType(event.target.value);
    };

    const handleChangeSetAction = (event) => {
        setAction(event.target.value);
    };

    const handleChangeSetUser = (event) => {
        setUsername(event.target.value);
    };

    const handleChangeSetCompany = (event) => {
        setCompany(event.target.value);
    };

    const handleChangeSetVideo = (event) => {
        setVideo(event.target.value);
    };



    const typeOptions = [
        {
            value: "0",
            name: "Todos"
        },
        {
            value: "video",
            name: "Video"
        },
        {
            value: "search",
            name: "Pesquisa"
        }
    ]

    const actionOptions = [
        {
            value: "0",
            name: "Todos"
        },
        {
            value: "like",
            name: "Like"
        },
        {
            value: "dislike",
            name: "Dislike"
        }
    ]


    return (


        <div id="Review">
            <Navbar warning={false} />
            <div className="container-fluid">
                <div className="row d-flex justify-content-center">
                    <form>
                        <div className="row d-flex justify-content-center">
                            <Box className='col-auto mt-3 mb-3'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Começo do período"
                                        inputFormat="dd/MM/yyyy"
                                        value={startDate}
                                        maxDate={new Date(endDate)}
                                        onChange={(newStartDate) => {
                                            let date = newStartDate.toISOString()
                                            setStartDate(date);
                                        }}

                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box className='col-auto mt-3 mb-3'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Final do período"
                                        inputFormat="dd/MM/yyyy"
                                        value={endDate}
                                        minDate={new Date(startDate)}
                                        onChange={(newEndDate) => {
                                            let date = newEndDate.toISOString()
                                            setEndDate(date);
                                        }}

                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box className="col-auto mt-3 mb-3">
                                <FormControl >
                                    <InputLabel id="demo-simple-select-label">Nome de usuário</InputLabel>
                                    <Select
                                        sx={{ display: "inline-flex", width: 200 }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="username"
                                        value={username}
                                        onChange={handleChangeSetUser}
                                    >
                                        <MenuItem value={0}>Todos</MenuItem>
                                        {allUsers.map((user) => {
                                            return (<MenuItem value={user._id}>{user.name}</MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className="col-auto mt-3 mb-3">
                                <FormControl >
                                    <InputLabel id="demo-simple-select-label">Video</InputLabel>
                                    <Select
                                        sx={{ display: "inline-flex", width: 200 }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Video"
                                        value={video}
                                        onChange={handleChangeSetVideo}
                                    >
                                        <MenuItem value={0}>Todos</MenuItem>
                                        {allVideos.map((video) => {
                                            return (<MenuItem value={video._id}>{video.title}</MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            {/* <Box className="col-auto mt-3 mb-3">
                                <FormControl >
                                    <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
                                    <Select
                                        sx={{ display: "inline-flex", width: 200 }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Company"
                                        value={company}
                                        onChange={handleChangeSetCompany}
                                    >
                                        <MenuItem value={0}>Todos</MenuItem>
                                        {allCompanies.map((company) => {
                                            return (<MenuItem value={company._id}>{company.name}</MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                            </Box> */}
                            <Box className="col-auto mt-3 mb-3">
                                <FormControl >
                                    <InputLabel id="demo-simple-select-label">Ação</InputLabel>
                                    <Select
                                        sx={{ display: "inline-flex", width: 200 }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Action"
                                        value={action}
                                        onChange={handleChangeSetAction}
                                    >
                                        {actionOptions.map((action) => {
                                            return (<MenuItem value={action.value}>{action.name}</MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className="col-auto mt-3 mb-3">
                                <FormControl sx={{ display: "inline-flex", width: 200 }}>
                                    <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Type"
                                        value={type}
                                        onChange={handleChangeSetType}
                                    >
                                        {typeOptions.map((type) => {
                                            return (<MenuItem value={type.value}>{type.name}</MenuItem>)
                                        })}

                                    </Select>
                                </FormControl>
                            </Box>
                            <div className="col-lg-auto d-flex justify-content-center mt-3 mb-3">
                                <Button size="large" variant="contained" onClick={handleSubmit} type="submit" className="btn btn-primary mt-2">Filtrar</Button>
                            </div>
                        </div>
                    </form>
                    <div id='responsive-table' className='tab-content table-responsive text-center'>
                        {loading ? <CircularProgress className='mt-5' /> :
                            <table className="table table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th scope="col">Nome do usuário</th>
                                        <th scope="col">Ação</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Nome da Empresa</th>
                                        <th scope="col">Pesquisa</th>
                                        <th scope="col">Video</th>
                                        <th scope="col">Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!filtered ? <>
                                        {Object.keys(allReviews).length == 0 ?
                                            <tr>
                                                <td className="text-center" colSpan="7">Nenhum registro encontrado</td>
                                            </tr>
                                            :
                                            <>
                                                {
                                                    allReviews.map((review) => {
                                                        let date = new Date(review.created_at)

                                                        return (
                                                            <tr className="text-center" key={review._id}>
                                                                <td>{!review.user ? null : review.user.name}</td>
                                                                <td>{review.action}</td>
                                                                <td>{review.type}</td>
                                                                <td>{!review.user ? null : review.user.company.name}</td>
                                                                <td>{!review.model ? null : review.model.value}</td>
                                                                <td>{!review.model ? null : review.model.title}</td>
                                                                <td>{date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear()}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </>
                                        }

                                    </>
                                        :
                                        <>
                                            {Object.keys(allFilteredReviews).length == 0 ?
                                                <tr>
                                                    <td className="text-center" colSpan="7">Nenhum registro encontrado</td>
                                                </tr>
                                                :
                                                <>
                                                    {allFilteredReviews.map((review) => {
                                                        let date = new Date(review.created_at)

                                                        return (
                                                            <tr className="text-center" key={review._id}>
                                                                <td>{!review.user ? null : review.user.name}</td>
                                                                <td>{review.action}</td>
                                                                <td>{review.type}</td>
                                                                <td>{!review.user ? null : review.user.company.name}</td>
                                                                <td>{!review.model ? null : review.model.value}</td>
                                                                <td>{!review.model ? null : review.model.title}</td>
                                                                <td>{date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear()}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div >
        </div >
    )
}


