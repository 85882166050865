import React from "react";
import MenuDrawer from "../Menu";

const Navbar = (props) => {
    const warning = props.warning
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-blue-default">
            <a className="navbar-brand col-lg-2" href="/">
                <img src={`${process.env.REACT_APP_URL}/dist/brand/logocelebro.png`} className="rounded img-logocelebro" alt="Logo" width="220" height="100" />
            </a>
            {warning ?
                <div className="collapse navbar-collapse text-white d-flex justify-content-center col-lg-8">
                    <div className="d-flex justify-content-center">
                        <div className="ml-auto justify-content-center">
                            <div className='d-lg-flex justify-content-center'>
                                <img src={`${process.env.REACT_APP_URL}/dist/icons/cone.png`}
                                    className="d-lg-block d-xl-block d-none img-icon-cone mt-2" alt="cone" width="30"
                                    height="35" />
                                <h1 className='m-2'>Avisos Importantes</h1>
                            </div>
                            <p>Dedicamos tempo apenas para você e caso queira compartilhar você precisa nos avisar</p>
                        </div>
                    </div>
                </div>
                :
                <div className="collapse navbar-collapse text-white d-flex justify-content-center col-lg-8">
                    <div className="d-flex justify-content-center">
                        <div className="ml-auto justify-content-center">
                            <div className='d-lg-flex justify-content-center'>
                                <h1 className='m-2'><b>Bem vindo ao DATI+</b></h1>
                            </div>
                            <p>Relembre funções do DATI ou descubra novas</p>
                        </div>
                    </div>
                </div>
            }
            <div className="col-lg-2">
                <MenuDrawer />
            </div>
        </nav>
    )
}

export default Navbar