import { getJson, get, postFile } from "./requests"
import { type } from "@testing-library/user-event/dist/type";
const videoService = {
    search: async function (keyword, category_id = null) {

        let url = `api/v1/video/search?keyword=${keyword}`

        if (category_id != null) {
            url += `&category_id=${category_id}`
        }
        const response = await getJson(url)
        return response
    },

    getVideoIdByLink:  function (link) {
        if (typeof link === 'undefined' || link === null) 
            return false
        const params = new URL(link)
        return params.searchParams.get("v")
    },

    getAll: async function () {

        const response = get('api/v1/video/all')
            .then(response => {
                return response.json().then(Video => ({ Video }))
            })
        return response
    },

    downloadFile: async function () {

        const file = get('api/v1/upload/export')
            .then(response => {
                return response.json().then(File => ({ File }))
            })
        return file
    }
}
export default videoService