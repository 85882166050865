import React, { useState } from 'react';
import './Login.css';
import UserService from '../../services/userService';
import Alert from '@mui/material/Alert';
import { CircularProgress } from '@mui/material';

export default function Login() {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [message, setMessage] = useState('fade row');

    const [loading, setLoading] = useState(false)

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setMessage('fade row')
            setLoading(true)
            await UserService.signIn(username, password);
            window.location.reload()
        } catch (err) {
            setMessage('text-white error row')
            setLoading(false)
            console.log(err)
        }

    }

    return (
        <div id='login-css' className='d-lg-flex'>
            <div className='col-lg-8 d-lg-block d-xl-block d-none'>
                <div className="d-lg-flex h-100 justify-content-center">
                    <div className='align-self-center'>
                        <div className='text-center'>
                            <img src={`${process.env.REACT_APP_URL}/dist/background/background2.jpeg`} className="img-fluid" alt="login-background" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 col-lg-4 p-5 vh-100 bg-blue-default'>
                <div className='d-flex h-100 justify-content-center'>
                    <div className="align-self-center">
                        <div className="d-block">
                            <div className='text-center text-white'>
                                <h1><b>Bem vindo ao DATI+</b></h1>
                                <p>Relembre funções do Dati apresentadas em reunião ou descubra novas.</p>
                            </div>
                        </div>
                        <div className="d-block">
                            <img src={`${process.env.REACT_APP_URL}/dist/brand/logomarca.png`} className="img-fluid img-logo" alt="login-background" />
                        </div>
                        <div className="d-block">
                            <form onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="col-lg-10 mb-3">
                                        <input type="email" className="form-control" placeholder="Email" id="validationDefault01" required onChange={e => setUserName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-lg-10 mb-3">
                                        <input type="password" className="form-control" placeholder="Senha" id="validationDefault03" required onChange={e => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-lg-10 mb-3">
                                        <button type="submit" className="btn btn-outline-primary border-color-blue-default text-color-blue-default" disabled={loading}
                                        >
                                            {loading ? <CircularProgress /> : "ENTRAR"}</button>
                                    </div>
                                </div>
                                <div className={message + 'justify-content-center col-lg-10 mb-3'}>
                                    <Alert severity="warning">Usuário ou senha incorretos!</Alert>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}