import UserService from "./userService"

const endpoint = process.env.REACT_APP_API

export const header = () => {
    const user = UserService.getUserLocalStorage()

    if (typeof user.token === "undefined")
        return { 'Accept': 'application/json', 'Content-Type': 'application/json' }

    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`,
        'Access-Control-Allow-Origin': '*'
    }
}

export const get = async (controller, action = null, params = null) => {
    let buildContext = controller

    if (action)
        buildContext += `/${action}`
    if (params)
        buildContext += `?${params}`

    return await fetch(`${endpoint}/${buildContext}`, {
        method: 'GET',
        headers: header()
    })
}

export const put = async (controller, params = {}) => {
    let buildContext = controller

    return await fetch(`${endpoint}/${buildContext}`, {
        method: 'PUT',
        headers: header(),
        body: JSON.stringify(params)
    })
}

export const getJson = async (controller, action = null, params = null) => {
    let buildContext = controller

    if (action)
        buildContext += `/${action}`
    if (params)
        buildContext += `?${params}`

    const response = await fetch(`${endpoint}/${buildContext}`, {
        method: 'GET',
        headers: header()
    })

    return await response.json()
}

export const post = async (controller, params = {}) => {
    let buildContext = controller

    return await fetch(`${endpoint}/${buildContext}`, {
        method: 'POST',
        headers: header(),
        body: JSON.stringify(params)
    })
}

export const postFile = async (controller, file, params = {}) => {

    let buildContext = controller
    const user = UserService.getUserLocalStorage = () => {

        if (typeof user.token === "undefined")
            return { 'Accept': 'application/json', 'Content-Type': 'application/json' }

        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
        }
    }


    return await fetch('http://localhost/api/v1/upload/import', {
        method: 'POST',
        headers: {
            'Accept': '*/*',
            'Content-Type': '*/*',
            'Accept-Encoding': 'gzip, deflate, br'

        },
        body: file
    }).then(
        response => response.json()
    ).then(
        success => console.log(success)
    ).catch(
        error => console.log(error)
    );
}

export const deletar = async (controller, params = {}) => {
    let buildContext = controller

    return await fetch(`${endpoint}/${buildContext}`, {
        method: 'DELETE',
        headers: header(),
        body: JSON.stringify(params)
    })
}